<template>
  <div class="background">
    <div class="left_box">
      <div class="loginForm">
        <div class="formTitle">
          <div class="passwordLoginForm">
            <p>密码登陆</p>
          </div>
        </div>
        <div class="form">
          <div class="passwordLoginForm">
            <p class="title">朗云管理员</p>
            <p class="secTitle">通过填写表格登陆您的账户</p>
            <div class="input_area">
              <p>手机号/邮箱</p>
              <input type="text" v-model="loginForm.adminname">
              <p>密码</p>
              <input type="password" v-model="loginForm.password">
             <el-button @click="login()">登录</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightPic"></div>

  </div>
</template>

<script>
import {h, ref} from "vue";
import {ElDivider} from "element-plus";
import Signup from "@/views/Home/SignUp";
export default {

  name: "ADLOG",
  components: {Signup},
  data(){
    return{
      spacer:h(ElDivider, { direction: 'vertical' }),
      loginForm:{
        adminname:'laowang',
        password:'',
      },
      rememberMe:ref([''])
    }
  },
  created() {
    if (window.sessionStorage.getItem("login") === "1"){
      this.$router.push('/AdministerBackHome');
    }
  },
  mounted() {

  },
  methods: {
    login() {
      this.$http({
        method: 'post',
        url: '/admin/login',
        data: this.loginForm
      }).then(res => {
        if (res.data.code === 0) {
          this.$notify({
            title: '登陆失败',
            message: res.data.message,
            type: 'warning'
          });
        }else {
          //登陆提示
          this.$notify({
            title: '登陆成功',
            message: '你好，管理员',
            type: 'success'
          });
          window.sessionStorage.setItem("token", res.data.token);
          window.sessionStorage.setItem("login", "1");
          window.sessionStorage.setItem("role", res.data.role);
          location.reload();
        }
      })
    }
  },
}

</script>

<style scoped>
.background{
  height: 934px;
  width: 1920px;

}


.left_box{
  position: absolute;
  width: 960px;
  height: 934px;
  background-image: url("../../images/login_bac1.jpg");
  background-size: 100% 100%;
}

.loginForm{
  position:absolute;
  top: 144px;
  left: 180px;
  width: 656px;
  height: 646px;
  box-shadow: #d4d2d2 0 15px 20px;
  -webkit-box-shadow: #d4d2d2 0 0 20px;
  -moz-box-shadow: #d4d2d2 0 0 20px;
  padding-left: 50px;
  padding-right: 51px;
  padding-top: 55px;
  background-color: #ffffff;
  border-radius: 10px;
}

.loginForm .eye{
  position: absolute;
  right: 50px;
  bottom: 185px;

}
.loginForm .form{

}

.loginForm .formTitle{
  width: 556px;
  height: 52px;
  margin: 0 auto;
  border-bottom: 1px solid #979797;
  padding-left: 10px;
}


.loginForm .formTitle div{
  width: 174px;
  height: 52px;
  float: left;
  text-align: center;
  font-size: 24px;
  color: #212121;
  font-weight: 500;
}

.loginForm .formTitle .passwordLoginForm{
  width: 180px;
}

.loginForm .formTitle .passwordLoginForm p{
  float: left;
  width: 179px;
  height: 52px;
  margin-bottom: 26px;
}

.loginForm .formTitle .passwordLoginForm i{
  display: block;
  float: left;
  width: 1px;
  height: 30px;
  background-color: #cccccc;
}

.loginForm .formTitle .passwordLogin p{
  float: left;
  width: 173px;
  height: 52px;
  margin-bottom: 26px;

}

.loginForm .formTitle .passwordLogin i{
  display: block;
  float: left;
  width: 1px;
  height: 30px;
  background-color: #cccccc;
}

.loginForm .formTitle .codeLogin{
  margin-bottom: 26px;
  /*margin-left: 45px;*/
  padding-left: 20px;

}

.loginForm .form{
  font-size: 21px;
  font-weight: 350;
  color: rgba(60, 60, 60, 0.55);
}

.loginForm .form .passwordLoginForm  .title{
  font-size: 36px;
  font-weight: 300;
  color: #212121;
  margin-top: 50px;
}

.loginForm .form .passwordLoginForm  .secTitle{
  font-size: 18px;
  font-weight: 300;
  margin-top: 22px;
  margin-bottom: 28px;
  color: #3c3c3c;
}

.form .passwordLoginForm .input_area p{
  height: 21px;
}

.form .passwordLoginForm input{
  /*background-color: black;*/
  height: 30px;
  width: 100%;
  margin-bottom: 31px;
  border-bottom: 2px solid #d4d4d4;
  margin-top: 24px;

}

.form .passwordLoginForm .signup{
  display: table;
  margin-top: 38px;
  font-size: 18px;
  font-weight: 400;
}

.form .passwordLoginForm .signup p{
  display: table-cell;
}

.form .passwordLoginForm .signup p:nth-child(2){
  margin-left: 10px;
  color: rgb(235,66,77);
  cursor: pointer;
}

.form .passwordLoginForm button{
  display: table-cell;
  background-color: rgb(235,66,77);
  color: white;
  font-size: 21px;
  /*font-weight: 700;*/
  width: 138px;
  height: 48px;
  left: 51px;
  line-height: 48px;
  border-radius: 5px;
  margin-left: 255px;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  color: #ffffff;
  transition: all 1s;
}

.form .passwordLoginForm button:hover{
  background-size: 300%;
}

.rightPic{
  position:absolute;
  right:0;
  width:960px;
  height:934px;
  background-image: url("../../images/login_bac2.jpg");
  background-size: 100% 100%;
}


</style>